import cx from 'classnames'

import { makeStyles, Theme } from '@material-ui/core/styles'
import first from './first.svg'
import second from './second.svg'
import third from './third.svg'
import fourth from './fourth.svg'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  background: {
    position: 'absolute',
    width: '110%',
    height: '100%',
    left: '-5%',
    right: '-5%',
    top: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    zIndex: 1,
  },
  first: {
    backgroundImage: `url('${first}')`,
  },
  second: {
    height: '85%',
    top: '10rem',
    left: 'unset',
    right: 'unset',
    backgroundImage: `url('${second}')`,
    backgroundPosition: 'center',
    [breakpoints.up('sm')]: {
      top: '11.5rem',
    },
    [breakpoints.up('md')]: {
      height: '100%',
      top: 0,
      left: '12rem',
    },
  },
  third: {
    backgroundSize: 'cover',
    backgroundImage: `url('${third}')`,
    backgroundPosition: 'top',
    top: '4rem',
  },
  fourth: {
    backgroundSize: 'auto',
    backgroundImage: `url('${fourth}')`,
    backgroundPosition: 'top',
    [breakpoints.up('md')]: {
      left: '-35rem',
      backgroundPosition: 'center',
    },
    [breakpoints.up('lg')]: {
      left: '-40rem',
    },
  },
}))

export type BackgroundProps = {
  variant: 'first' | 'second' | 'third' | 'fourth'
}

const Background = ({ variant }: BackgroundProps) => {
  const classes = useStyles({})

  let className
  switch (variant) {
    case 'first':
      className = classes.first
      break
    case 'second':
      className = classes.second
      break
    case 'third':
      className = classes.third
      break
    case 'fourth':
      className = classes.fourth
      break
    default:
      className = classes.first
  }

  return <div className={cx(classes.background, className)} />
}

export default Background
