import { useRef, useEffect } from 'react'

/**
 * Inspired by https://medium.com/@rajeshnaroth/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
 */
export function makeCancelable(promise: Promise<any>): CancellablePromise {
  let isCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => !isCanceled && resolve(val)).catch(error => !isCanceled && reject(error))
  })

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true
    },
  }
}

type CancellablePromise = {
  promise: Promise<any>
  cancel: () => void
}

const useCancellablePromise = (cancelable = makeCancelable) => {
  const emptyPromise = Promise.resolve(true)

  // test if the input argument is a cancelable promise generator
  if (cancelable(emptyPromise).cancel === undefined) {
    throw new Error('promise wrapper argument must provide a cancel() function')
  }

  const promises = useRef<CancellablePromise[]>([])

  useEffect(() => {
    promises.current = promises.current || []
    return function cancel() {
      promises.current.forEach(p => p.cancel())
      promises.current = []
    }
  }, [])

  function cancellablePromise(p: Promise<any>) {
    const cPromise = cancelable(p)
    promises.current.push(cPromise)
    return cPromise.promise
  }

  return cancellablePromise
}

export default useCancellablePromise
