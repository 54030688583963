import gql from 'graphql-tag'

export default gql`
  query ReferralBanner($referralUrl: String!) {
    referralUser: findUserByReferralUrl(referralUrl: $referralUrl) {
      id
      firstName
      displayName
      image
      tutors {
        id
      }
    }
  }
`
