import gql from 'graphql-tag'

export default gql`
  query startChatModal($profileId: String!) {
    profile: getProfileFixed(id: $profileId) {
      ... on Tutor {
        id
        user {
          id
          firstName
          displayName
          tutors {
            id
          }
        }
      }
      ... on Student {
        id
        user {
          id
          firstName
          displayName
          tutors {
            id
          }
        }
      }
    }
  }
`
