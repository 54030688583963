import gql from 'graphql-tag'

export default gql`
  query getChatConversation($id: String!) {
    getConversation(partnerUserId: $id) {
      id
      user {
        id
        firstName
        displayName
      }
    }
  }
`
