import React from 'react'
import { useTranslation } from '@src/i18n'
import NoSsr from '@material-ui/core/NoSsr'
import Link from '@base/Link'
import links, { externalContentLinks } from '@api/links'
import BoxContainer from '@base/BoxContainer'
import Logo from '@base/Logo'
import Box from '@base/Box'
import Image from '@base/Image'
import Typography from '@base/Typography'
import { now } from '@lib/withTimeSync'
import { isStandalone } from '@helpers/browser'
import facebook from './footer/facebook.svg'
import instagram from './footer/instagram.svg'
import youtube from './footer/youtube.svg'
import { UrlObject } from 'url'

const Footer = () => {
  return (
    <NoSsr>
      <FooterImpl />
    </NoSsr>
  )
}

const FooterImpl = () => {
  const { t } = useTranslation()

  const social = [
    {
      alt: 'Facebook',
      href: 'https://www.facebook.com/myterap.io',
      src: facebook,
    },
    {
      alt: 'Instagram',
      href: 'https://www.instagram.com/terap.io/',
      src: instagram,
    },
    {
      alt: 'Youtube',
      href: 'https://www.youtube.com/channel/UC74WEu1QLUiP1s0NFWpOaoA/featured?view_as=subscriber',
      src: youtube,
    },
  ]

  const footerItems = [
    {
      section: t('navigation.about'),
      items: [
        {
          ...externalContentLinks.joinUs,
          title: t('navigation.join'),
          testId: 'footerMenuJoinUs',
        },
        {
          title: t('navigation.therapists'),
          href: links.tutorSearch,
          testId: 'footerMenuAllTherapists',
        },
        {
          title: t('navigation.blog'),
          href: links.blog,
          external: true,
          testId: 'footerMenuBlog',
        },
        {
          title: t('navigation.privacy'),
          href: links.privacy,
          testId: 'footerMenuPrivacy',
        },
        {
          ...externalContentLinks.terms,
          title: t('navigation.terms'),
          testId: 'footerMenuTerms',
        },
      ] as { title: string, href: string | UrlObject, testId: string, external?: boolean }[]
    },
  ]

  if (isStandalone()) {
    return null
  }

  return (
    <BoxContainer
      component='footer'
      py={3.75}
      px={3.75}
      borderTop={{ md: '1px solid #D0D8E0' }}
      innerProps={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
    >
      <Box pr={{ md: 3 }}>
        <Link href={links.home}>
          <Logo />
        </Link>
        <Box color='#7B8794'>
          <Typography variant='caption'>{t('common.copyright', { year: new Date(now()).getFullYear() })}</Typography>
        </Box>
        <Box color='#7B8794'>
          <Typography variant='caption'>
            {t('contact.title')}: {t('contact.email')} | {t('contact.phone')}
          </Typography>
        </Box>
        <Box my={3.75} display='flex' alignContent='center' alignItems='center'>
          {social.map(({ alt, href, src }) => (
            <Box key={alt} mr={2}>
              <Link external {...{ href }}>
                <Image {...{ src, alt }} />
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
      {footerItems.map(({ section, items }) => (
        <Box
          key={section}
          display='flex'
          flexDirection='column'
          textAlign={{ xs: 'center', sm: 'right' }}
          mt={6}
          pl={{ md: 5 }}
        >
          {items.map(({ title, href, external, testId }) => (
            <Link key={title} {...{ href, external }} data-testid={testId}>
              {title}
            </Link>
          ))}
          <Box color='#7B8794' mt={3}>
            <Typography variant='caption'>{t('cookies')}</Typography>
          </Box>
        </Box>
      ))}
    </BoxContainer>
  )
}

export default Footer
