import { ReactNode, ComponentProps } from 'react'
import Box from '@base/Box'

import Background, { BackgroundProps } from '@pages/index/background/Background'

export type Props = ComponentProps<typeof Box> & {
  children: ReactNode
  innerProps?: ComponentProps<typeof Box>
  background?: BackgroundProps['variant']
}

const Container = ({ background, children, innerProps, ...props }: Props) => (
  <Box width='100%' px={{ xs: 2, sm: 3, md: 4, lg: 5 }} {...props}>
    <Box width='100%' maxWidth={1600} mx='auto' zIndex={background ? 2 : null} {...innerProps}>
      {children}
    </Box>
    {background && <Background variant={background} />}
  </Box>
)

export default Container
