import { ApolloError } from 'apollo-boost'
import { useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/react-hooks'

import {
  GetChatConversationQuery,
  GetChatConversationQueryVariables,
} from '@gql/__generated__'
import GET_CONVERSATION from '@gql/queries/getChatConversationQuery.api'
import {
  CreateConversationMutation,
  CreateConversationMutationVariables,
} from '@gql/__generated__'
import CREATE_CONVERSATION from '@gql/mutations/createConversationMutation.api'

type Conversation = CreateConversationMutation['createConversation']

type StartChatFn = () => Promise<{
  link: {
    href: string
    as: string
  }
  conversation: Conversation
}>

type UseStartChatHookResult = {
  conversation: Conversation | null
  startChat: StartChatFn
  loading: boolean
  error: ApolloError | null
}

export const createConversationLink = (conversation: Conversation) => ({
  href: `/messages?conversationId=${conversation.id}`,
  as: `/messages/${conversation.id}`,
})

const useStartChat = (userId: string): UseStartChatHookResult => {
  const [conversation, setConversation] = useState<Conversation>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const client = useApolloClient()

  const startChat = () => {
    setLoading(true)
    return client
      .query<GetChatConversationQuery, GetChatConversationQueryVariables>({
        // Fetch conversation using network unless we get any value. Then use cached value.
        fetchPolicy: !conversation ? 'network-only' : undefined,
        query: GET_CONVERSATION,
        variables: {
          id: userId,
        },
      })
      .then(({ data }) => {
        const { getConversation: conv } = data
        const link = conv ? createConversationLink(conv) : null

        setConversation(conv)
        setLoading(false)
        return { link, conversation: conv }
      })
      .catch(err => {
        setLoading(false)
        setError(err)
        throw err
      })
  }

  return {
    conversation,
    loading,
    error,
    startChat,
  }
}

type CreateChatFn = (
  partnerUserId: string,
) => Promise<{
  link: {
    href: string
    as: string
  }
  conversation: CreateConversationMutation['createConversation']
}>

type UseCreateChatHookResult = {
  conversation: CreateConversationMutation['createConversation']
  createChat: CreateChatFn
  loading: boolean
  error: ApolloError | null
}

export const useCreateChat = (): UseCreateChatHookResult => {
  const [conversation, setConversation] = useState<Conversation>(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const [createConversation] = useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CREATE_CONVERSATION)

  const create: CreateChatFn = (partnerUserId: string) => {
    setLoading(true)

    return createConversation({
      variables: {
        conversationInput: {
          isCall: false,
          partnerUserId,
        },
      },
    })
      .then(({ data: { createConversation: conv } }) => {
        const link = createConversationLink(conv)

        setConversation(conv)
        setLoading(false)
        setError(null)
        return { link, conversation: conv }
      })
      .catch(err => {
        setConversation(null)
        setLoading(false)
        setError(err)
        throw err
      })
  }

  return {
    conversation,
    createChat: create,
    loading,
    error,
  }
}

export default useStartChat
