import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import getConfig from 'next/config'
import React from 'react'
import { useSelector } from 'react-redux'

import links from '@api/links'
import Avatar from '@base/Avatar'
import Container, { Props as ContainerPropsType } from '@base/Container'
import Link from '@base/Link'
import Box from '@material-ui/core/Box'
import Button from '@base/Button'
import Typography from '@base/Typography'
import Well from '@base/Well'
import { ReferralBannerQuery, ReferralBannerQueryVariables } from '@gql/__generated__'
import useExchangeRate from '@hooks/useExchangeRate'
import { AppState } from '@store/Types'
import { sourceSelectors } from '@store/modules/Source'
import { userSelectors } from '@store/modules/User'
import { useTranslation } from '@src/i18n'

import QUERY from './referralBanner/Query.api'
import { makeDualPrice, makePrice } from '@helpers/price'
import { useRouter } from 'next/router'

const { REFERRAL, REFERRAL_REWARD } = getConfig().publicRuntimeConfig

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  imageColumn: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'block',
    },
  },
  ctaRow: {
    [breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
}))

type Props = {
  ContainerProps?: Omit<ContainerPropsType, 'children'>
}

type ImplProps = Props & {
  classes: ReturnType<ReturnType<typeof makeStyles>>
  referralUrl: string
}

const ReferralBanner = (props: Props) => {
  // We are passing styles to the child component so that useStyles() hooks calls are stable on server and client
  // (otherwise this would cause style mismatch issue)
  const classes = useStyles({})
  const router = useRouter()
  const referralUrl = useSelector<AppState, string>(state =>
    sourceSelectors.getReferralUrl(sourceSelectors.getSelf(state)),
  )
  const isLoggedIn = useSelector<AppState, boolean>(state => userSelectors.isLoggedIn(userSelectors.getSelf(state)))
  const isTutor = useSelector<AppState, boolean>(state => userSelectors.isTutor(userSelectors.getSelf(state)))
  const isOnReferralUrl = typeof router.query.invite === 'string'

  // Visible for non-logged user or student only
  if (isLoggedIn || isTutor) {
    return null
  }

  // Visible only on referral landing page (contains query parameter) with source.referralUrl
  if (!REFERRAL || !isOnReferralUrl || !referralUrl) {
    return null
  }

  return <ReferralBannerImpl classes={classes} referralUrl={referralUrl} {...props} />
}

const ReferralBannerImpl = ({ ContainerProps, classes, referralUrl }: ImplProps) => {
  const { t } = useTranslation()

  const { data: { referralUser = null } = {}, loading, error } = useQuery<
    ReferralBannerQuery,
    ReferralBannerQueryVariables
  >(QUERY, {
    variables: {
      referralUrl,
    },
  })
  const loaded = !loading && !error
  const [exRate, exCurrency] = useExchangeRate()

  // Do not display any error here
  if (!referralUrl || !loaded || !referralUser) {
    return null
  }

  return (
    <Container {...ContainerProps}>
      <Well color='#323F4B' bgcolor='#C6F7E9' padding={{ xs: 2, md: 3 }} width='100%'>
        <Grid alignItems='center' container spacing={3}>
          <Grid className={classes.imageColumn} item xs='auto'>
            <Box height={80} width={80}>
              <Avatar alt={referralUser.firstName || referralUser.displayName} src={referralUser.image} size='100%' />
            </Box>
          </Grid>
          <Grid item xs={true}>
            <Box mb={{ xs: 1, md: 2 }}>
              <Typography variant='h6'>
                {referralUser.tutors.length
                  ? // When user is invited by tutor he/she gets first call only
                  t('referral.banner.title_tutor', {
                    name: referralUser.firstName || referralUser.displayName,
                  })
                  : // When user is invited by student he/she gets first call only
                  t('referral.banner.title', {
                    name: referralUser.firstName || referralUser.displayName,
                    freeCredit: makeDualPrice(makePrice(REFERRAL_REWARD), exRate, exCurrency),
                  })}
              </Typography>
            </Box>
            <Grid alignItems='center' className={classes.ctaRow} container spacing={2}>
              <Grid item xs={12} sm={true}>
                <Typography component='span'>{t('referral.banner.conditions')}</Typography>
              </Grid>
              <Grid item xs={12} md='auto'>
                <Link {...links.signup}>
                  <Button color='primary' variant='contained' size='small' data-testid='referralSignUpBtn'>
                    {t('referral.banner.signupBtn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Well>
    </Container>
  )
}

export default ReferralBanner
