import { useQuery } from '@apollo/react-hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import omit from 'lodash/omit'
import Router, { withRouter } from 'next/router'
import { WithRouterProps } from 'next/dist/client/with-router'
import { useCallback, useEffect, useState } from 'react'
import { parse } from 'url'

import getLogger from '../../Logger'
import Alert from '@base/Alert'
import Dialog from '@base/Dialog'
import Box from '@base/Box'
import Image from '@base/Image'
import Typography from '@base/Typography'
import Button from '@base/Button'
import chat from './startChatModal/chat.svg'
import { StartChatModalQuery, StartChatModalQueryVariables } from '@gql/__generated__'
import START_CHAT_MODAL from '@gql/queries/startChatModalQuery.api'
import Error from '@components/base/NetworkError'
import { useCreateChat } from './useStartChat'
import { useTranslation } from '@src/i18n'
import analytics from '@src/Analytics'
import useCancellablePromise from '@hooks/useCancellablePromise'

type Props = {
  profileId: string
  onCompleted?: () => void
  onClose: () => void
}

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    // Fullscreen on mobile
    [breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      height: '100%',
      minHeight: 0,
      width: '100%',
    },
    padding: spacing(2),
    [breakpoints.up('sm')]: {
      padding: spacing(6),
    },
  },
  // Prevents button to shrink in Safari when dialog height is lower then content height
  startBtn: {
    flexShrink: 0,
  },
}))

const StartChatModal = ({ profileId, onCompleted, onClose }: Props) => {
  const { data, error, loading } = useQuery<StartChatModalQuery, StartChatModalQueryVariables>(START_CHAT_MODAL, {
    variables: {
      profileId,
    },
  })
  const loaded = !loading && !error
  const { profile = null } = data || {}
  const classes = useStyles({})
  const { t } = useTranslation()
  const { createChat, error: chatError, loading: isChatLoading } = useCreateChat()
  const [isRedirecting, setRedirecting] = useState(false)
  const cancellablePromise = useCancellablePromise()

  useEffect(() => {
    if (!profile) {
      return
    }
    analytics.pushEvent('start-with-chat', { userName: profile.user.firstName, profileId: profile.id })
  }, [profile])

  const handleCreateConversation = () => {
    createChat(profile.user.id)
      .then(({ link }) => {
        setRedirecting(true)
        analytics.pushEvent('start-with-chat/success', { userName: profile.user.firstName, profileId: profile.id })
        onCompleted && onCompleted()

        cancellablePromise(Router.push(link.href, link.as))
          .then(() => {
            setRedirecting(false)

            return
          })
          .catch(err => getLogger().warn({ err }, 'Browser navigation failed'))

        return
      })
      .catch(err => {
        getLogger().error({ err }, 'Creating conversation failed')
      })
  }

  return (
    <Dialog
      className={classes.root}
      onClose={onClose}
      open={true}
      maxWidth='sm'
      aria-labelledby={t('chat.start.modal.title')}
    >
      {loading && (
        <Box alignItems='center' display='flex' height={200} mx='auto' width={200} justifyContent='center'>
          <CircularProgress color='inherit' size={40} thickness={5} />
        </Box>
      )}
      {error && <Error />}
      {loaded && (
        <>
          <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            // Prevents box to shrink in Safari when dialog height is lower then content height
            flexShrink={0}
            maxWidth={400}
            mx='auto'
            mb={2}
            justifyContent='center'
            textAlign='center'
          >
            <Box textAlign='center'>
              <Image src={chat} alt='Start with chat' height={150} width={150} />
            </Box>
            <Box mt={3.5} mb={1}>
              <Typography variant='h4' weight={600}>
                {t('chat.start.modal.title')}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant='body1'>
                {t('chat.start.modal.subtitle', { userName: profile.user.firstName || profile.user.displayName })}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant='body1' weight={600}>
                {profile.user.tutors.length
                  ? t('chat.start.modal.instruction1Tutor')
                  : t('chat.start.modal.instruction1Student')}
              </Typography>
            </Box>
          </Box>
          <Button
            className={classes.startBtn}
            disabled={isChatLoading || isRedirecting}
            fullWidth
            color='primary'
            variant='contained'
            onClick={handleCreateConversation}
          >
            {(isChatLoading || isRedirecting) && (
              <Box display='flex' mr={1}>
                <CircularProgress color='inherit' size={20} thickness={5} />
              </Box>
            )}
            {t('chat.start.modal.chatBtn')}
          </Button>
          {chatError && (
            <Box mt={2}>
              <Alert>{t('chat.start.error.getOrCreateConversationFailed')}</Alert>
            </Box>
          )}
        </>
      )}
    </Dialog>
  )
}

const StartChatModalWrapper = ({ router }: WithRouterProps) => {
  const { asPath, query, pathname } = router
  // Open with query parameter
  const profileId = query.startChat
  const isOpen = typeof profileId === 'string'

  const handleClose = useCallback(() => {
    const { pathname: asPathname, query: asQuery } = parse(asPath, true)
    Router.push(
      { pathname, query: { ...omit(query, ['startChat']), scroll: false } },
      { pathname: asPathname, query: omit(asQuery, ['startChat']) },
      { shallow: true },
    ).catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
  }, [asPath, query, pathname])

  if (!isOpen) {
    return null
  }

  return <StartChatModal profileId={profileId as string} onClose={handleClose} onCompleted={handleClose} />
}

export default withRouter(StartChatModalWrapper)
