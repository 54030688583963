import { Omit } from '@material-ui/core'
import React, { ReactNode } from 'react'

import Box, { Props as BoxProps } from '@base/Box'
import StartChatModal from '@tutor/StartChatModal'

import NavBar from './NavBar'
import HeaderContainer, { Props as HeaderContainerProps } from './HeaderContainer'
import Footer from './Footer'
import ReferralBanner from './ReferralBanner'

type Props = {
  children: ReactNode
  footer?: boolean
  rootProps?: Omit<BoxProps, 'children'>
  contentProps?: Omit<BoxProps, 'children'>
  headerProps?: Omit<HeaderContainerProps, 'children'>
}

const Layout = ({ children, contentProps, footer = true, headerProps, rootProps }: Props) => {
  return (
    <Box maxWidth='100%' overflow='hidden' {...rootProps}>
      {/* Stretch header and content to at least full viewport height */}
      <Box display='flex' flexDirection='column' minHeight='100vh'>
        <HeaderContainer {...headerProps}>
          <NavBar />
        </HeaderContainer>
        <ReferralBanner ContainerProps={{ mb: 4 }} />
        <Box flexGrow={1} {...contentProps}>
          {children}
        </Box>
      </Box>
      {footer && <Footer />}

      <StartChatModal />
    </Box>
  )
}

export default Layout
